body {
    background-color: white;
}

.home-header {
    color: white;
    padding: 40px 20px;
    background-image: url('./static/header.jpg');
    margin-bottom: 30px;
}

.home-header p {
    font-weight: bold;
    padding: 10px 0px;
}

.about p {
    padding: 0px 10px;
    text-align: justify;
}

.onlyScreenWithGivenWidth p {
    text-align: justify;
}

.projects .container {
    padding: 0px;
}

.onlyPrintAndMobile {
    padding-bottom: 60px;
}

.onlyPrintAndMobile h1 {
    padding: 0px 10px;
    border-bottom: 2px solid grey;
}

.onlyPrintAndMobile p {
    margin-bottom: 5px;
}

.about table a {
    color: deepskyblue;
}

.experience-header img {
    margin: auto;
    width: 100%;
    padding: 40px;
}

.experience-header #introduction {
    text-align: justify;
    margin: auto;
    padding: 40px 10px 40px 0px;
}

.experience-header #introduction h3 {
    text-align: left;
}

.experience-header Table {
    margin-top: 40px;
}

.experience-header a {
    color: deepskyblue;
}

.onlyPrintAndMobileView p {
    margin-bottom: 5px;
}

.cards {
    padding: 10px 10px;
}

.cards img {
    padding-top: 10px;
    max-width: 80%;
    margin: auto;
}

.cards h1 {
    padding: 30px 0px 0px 0px;
    border-bottom: 2px solid grey;
}

.cards #subtitle {
    font-style: italic;
}

.cards ul {
    text-align: justify;
}

.languages, .programmer-skills {
    padding: 0px 10px;
}

.languages h1 {
    padding: 30px 0px 0px 0px;
    border-bottom: 2px solid grey;
}

.languages h5 {
    padding-top: 20px;
}

.languages #subtitle {
    font-style: italic;
}

.programmer-skills h1 {
    padding: 30px 0px 0px 0px;
    border-bottom: 2px solid grey;
}

#button {
    padding: 20px 48%;
}

.g-recaptcha {
    padding-bottom: 20px;
    border-radius: 4px;
    overflow: hidden;
    transform:scale(0.77);
    transform-origin:0 0;
    margin: auto;
}

@media print {
    .onlyScreen {
        display: none;
    }

    #button {
        display: none;
    }

    #button2 {
        display: none;
    }
}

@media print, screen and (max-width: 1199px) {
    .onlyScreenWithGivenWidth {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .about-header img {
        width: 100%;
    }
}

@media screen and (min-width: 1200px) {
    .onlyPrintAndMobile {
        display: none;
    }
}

@media screen {
    #onlyPrint {
        display: none;
    }
}

@media print, screen and (min-width: 768px) and (max-width: 999px) {
    #rightColumn {
        padding-left: 55px;
    }
}

@media print, screen and (max-width: 1000px) {
    table {
        display: none;
    }

    a:link {
        text-decoration: none;
    }
}

@media screen and (min-width: 1000px) {
    .onlyPrintAndMobileView {
        display: none;
    }
}

@media screen and (max-width: 575px) {
    .experience-header img {
        padding-top: 0px;
    }
}

@media print {
    .avoidBreak {
        page-break-inside: avoid;
    }
}

#success-alert {
    -webkit-animation: cssAnimation 5s forwards;
    animation: cssAnimation 5s forwards;
}

@keyframes cssAnimation {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes cssAnimation {
    0% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

html {
    scroll-behavior: smooth;
}
